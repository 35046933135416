import * as React from 'react'
import { Divider, Typography, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

// This componenet could be remove it
const DashboardView = () => {
  const username = useSelector((state: RootState) => state.rootStore.userStore.username)
  document.title = 'Gems Admin Portal - Dashboard'
  return (
    <Grid container justifyContent='center' style={{ padding: '12px' }}>
      {/* DASH */}
      <Grid item xs={12}>
        <Typography variant='overline' fontSize={20}>
          Welcome,
          {` ${username}`}
        </Typography>
        <Divider />
      </Grid>
    </Grid>
  )
}

export default DashboardView
