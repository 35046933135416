import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
  DialogActions
} from '@mui/material'
import { useSelector } from 'react-redux'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { RootState } from '../store'
import { LIST_STULLER_USERS_BY_SHIP_TO } from '../graphql/queries/trialQueries'
import SearchBarComponent from './SearchBarComponent'

interface IByShipToUserSelectModalProps {
  handleShipToUserSearch: (username: string) => void
}

const ByShipToUserSelectModal: React.FC<IByShipToUserSelectModalProps> = ({ handleShipToUserSearch }) => {
  const accessToken = useSelector((state: RootState) => state.accessToken)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const [usernames, setUsernames] = useState<string[]>([])
  const [username, setUsername] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const regex = RegExp('[\\d]{1,6}-[\\d]{6,}$')

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event: SelectChangeEvent) => {
    setUsername(event.target.value as string)
  }

  const [listStullerUsersByShipTo, { loading, data, error }] = useLazyQuery(LIST_STULLER_USERS_BY_SHIP_TO, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    },
    fetchPolicy: 'network-only'
  })

  const handleSearchChange = (value: string) => {
    setSearchTerm(value)
  }

  const handleSearch = (): void => {
    const formattedSearch = searchTerm.trim().toLowerCase()
    console.log(`searchTerm: ${formattedSearch}`)
    if (regex.test(formattedSearch)) {
      listStullerUsersByShipTo({
        variables: {
          shipTo: formattedSearch
        }
      })
    } else {
      toast.error('Invalid Ship To format provided', { theme: 'colored' })
      setSearchTerm('')
      setUsernames([])
    }
  }

  useEffect(() => {
    if (error) {
      toast.error(error.message, { theme: 'colored' })
    }
    if (data) {
      setUsernames(data.listStullerUsersByShipTo.map((item: { loginId: string }) => item.loginId))
    }
  }, [error, data])

  return (
    <>
      <Button variant='text' sx={{ mb: 1 }} onClick={handleOpen}>
        By Ship-To
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '-webkit-fill-available',
            minWidth: 'fit-content'
          }
        }}
      >
        <DialogTitle>By Ship-To</DialogTitle>
        <DialogContent sx={{ gap: 2 }}>
          <SearchBarComponent
            handleSearchChange={handleSearchChange}
            handleSearch={handleSearch}
            placeHolderSearchText='Search by Ship-To Number (e.g. 141833-000001)'
            searchTextValue={searchTerm}
            elementId='searchUserByShipTo'
          />
          {loading ? (
            <LinearProgress />
          ) : (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id='username-label'>Username</InputLabel>
              <Select labelId='username-label' id='username' value={username} label='Username' onChange={handleChange}>
                {usernames.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={username === ''}
            variant='contained'
            onClick={() => {
              handleShipToUserSearch(username)
              handleClose()
            }}
          >
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ByShipToUserSelectModal
