import { Grid, LinearProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import SearchBarComponent from '../components/SearchBarComponent'
import { AccordionSection } from '../components/accordionSection'
import SoftwareAccessComponent from '../components/SoftwareAccess/softwareAccessComponent'
import { counterSketch, counterSketchStreaming, matrixGold3 } from '../data/Helpers'
import { GET_GEMS_USER_SOFTWARE_ACCESS } from '../graphql/queries/gemsQueries'
import { RootState } from '../store'

export interface ISoftwareAccessResult {
  applicationID: string
  flagName: string
  flag: boolean
}
interface ISoftwareAccessMenuProps {
  handleSearchChange: (value: string) => void
  handleSearch: () => void
  searchValue: string
  loading: boolean
}
const SoftwareAccessMenu: FC<ISoftwareAccessMenuProps> = ({
  handleSearch,
  handleSearchChange,
  searchValue,
  loading
}) => (
  <>
    <SearchBarComponent
      handleSearchChange={handleSearchChange}
      handleSearch={handleSearch}
      searchTextValue={searchValue}
      placeHolderSearchText='Username'
      elementId='searchUsersSoftwareAccess'
    />
    <LinearProgress style={{ display: loading ? 'block' : 'none' }} />
  </>
)

const UserSoftwareAccess = () => {
  const accessToken = useSelector((state: RootState) => state.accessToken)

  const [username, setUsername] = useState<string>('')
  const [gemsUserData, setGemsUserData] = useState<Array<ISoftwareAccessResult>>([])
  const [showControls, setShowControls] = useState<boolean>(false)
  const [userID, setUserId] = useState<string>('')
  const [userSoftwareAccessQuery, { data, loading }] = useLazyQuery(GET_GEMS_USER_SOFTWARE_ACCESS, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    fetchPolicy: 'network-only',
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })
  const resetView = () => {
    setGemsUserData([])
    setShowControls(false)
  }
  const handleSearchChange = (value: string) => {
    setUsername(value.toLowerCase())
    resetView()
  }
  const handleSearch = () => {
    userSoftwareAccessQuery({
      variables: {
        username: username.toLowerCase().trim()
      }
    })
  }

  useEffect(() => {
    if (data) {
      setGemsUserData(data.getGemsUser.softwareAccess.map((d: ISoftwareAccessResult) => d))
      setUserId(data.getGemsUser.userID)
      setShowControls(true)
    }
  }, [data])
  return (
    <div>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item xs={12}>
          <SoftwareAccessMenu
            handleSearch={handleSearch}
            handleSearchChange={handleSearchChange}
            searchValue={username}
            loading={loading}
          />
        </Grid>
        {showControls ? (
          <Grid item xs={12}>
            <AccordionSection
              sectionName='MatrixGold'
              content={[
                <SoftwareAccessComponent
                  userID={userID}
                  username={username}
                  applicationID={matrixGold3}
                  gemsUserData={gemsUserData}
                />
              ]}
            />
            <AccordionSection
              sectionName='CounterSketch'
              content={[
                <SoftwareAccessComponent
                  userID={userID}
                  username={username}
                  applicationID={counterSketch}
                  gemsUserData={gemsUserData}
                />
              ]}
            />
            <AccordionSection
              sectionName='CounterSketch Streaming'
              content={[
                <SoftwareAccessComponent
                  userID={userID}
                  username={username}
                  applicationID={counterSketchStreaming}
                  gemsUserData={gemsUserData}
                />
              ]}
            />
          </Grid>
        ) : (
          <Grid item xs={10}>
            <Typography align='center' color='text.secondary'>
              Search for a valid username to begin.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default UserSoftwareAccess
