import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, ReactNode } from 'react'
import { RootState } from '../../store'

interface PublicRouteProps {
  children: ReactNode
}

const ProtectedRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const navigate = useNavigate()
  const isAuthenticated = useSelector((state: RootState) => state.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/login', { replace: true })
      toast.warn('User is not authenticated')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <>{children}</>
}

export default ProtectedRoute
